<template>
  <div class="home">
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Últimas Vendas
    </h2>
  <!--  <div class="flex flex-wrap justify-between">
      <div class="flex-1 mr-3">
        <Card
          icon="log-in"
          :number="badges.visitas_dia"
          label="Visitas no dia"
          color="#9A163D"
        />
      </div>
      <div class="flex-1 mr-3 ml-3">
        <Card
          icon="calendar"
          :number="badges.visitas_mes"
          label="Visitas no mês"
          color="#9A163D"
        />
      </div>
      <div class="flex-1 mr-3 ml-3">
        <Card
          icon="user"
          :number="badges.users"
          label="Usuários"
          color="#9A163D"
        />
      </div>
      <div class="flex-1 ml-3">
        <Card
          icon="file-document"
          :number="badges.dicas"
          label="Dicas"
          color="#9A163D"
        />
      </div>
    </div>

    <hr class="my-9" />
    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
      <div class="w-full">
        <div class="flex-3 w-full">
          <h3 class="font-bold">Gráficos de Inteligência</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados dos
            usuários buscados pelo sistema.
          </span>
        </div>

        <div class="my-9 flex items-center">
          <div class="flex-1 mr-2">
            <Input
              type="date"
              label="Data Inicial"
              v-model="dataInicial"
              :maxDate="dataFinal"
            />
          </div>          
          <div class="flex-1 ml-2">
            <Input
              type="date"
              label="Data Final"
              v-model="dataFinal"
              :minDate="dataInicial"
            />
          </div>
          <div class=" ml-4 items-center">
            <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-5 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full`"
              @click="filtrarGraficos"
            >
              Filtrar 
            </button>
            
          </div>
        </div>
        
      </div>
    </div>-->


    <div class="card shadow-sm bg-white border p-8 mt-2">
        <h5 class="my-6 text-1xl font-semibold text-gray-700 dark:text-gray-200">
       Pagamentos feitos durante os últimos 30 dias
      </h5>
      <div class="card">
          <DataTable
            :headers="headersPagamento"
            :data="Pagamento"
            color="primary"
          />
        </div>
    </div>
    <hr class="my-9" />  
    <div class="card shadow-sm bg-white border p-8 my-2">
      <h5 class="my-6 text-1xl font-semibold text-gray-700 dark:text-gray-200">
       Vendas feitas durante os ultimos 15 dias
      </h5>
      <div class="card">
          <DataTable
            :headers="headersVenda"
            :data="Venda"
            color="primary"
          />
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, ref } from "vue";
import { GET } from "../services/api";
import {toMoney} from '../services/formater';
import DataTable from "../components/Elements/Datatable.vue";
import moment from 'moment-timezone';

export default defineComponent({
  name: "Home",

  components: {
    DataTable,
  },

  data() {
        return{
            isMobile: false
        }
    },
  created() {
          this.isMobile = window.innerWidth < 800 ?  true : false;
          window.addEventListener('resize', () => {
              this.isMobile = window.innerWidth < 800 ?  true : false;
          })
      },
  
  setup() {
    const loader= inject("loading");
    const Pagamento = ref([])
    const Venda = ref([])
    const eventos = ref([])
    const headersPagamento = [
      { key: "Id", value: "id"},
      {key: "Data", value:"data"},
      {key: "Evento", value:"evento"},
      {key: "Descrição", value:"descricao"},
      {key: "Valor", value: "valor"}
  ]

    const headersVenda = [
      { key: "Id", value: "id"},
      { key: "Comprador", value: "Nome_Comprador"},
      {key: "Data", value:"data"},
      {key: "Evento", value:"evento"},
      {key: "Descrição", value:"detalhes"},
      {key: "Valor", value: "valor"}  ,
      {key: "Pedido", value: "pedido"}  ,
      {key: "Meio de Pagamento", value: "meio_pagamento"}  
  
  ]

    const badges = ref({
      ['visitas_dia']: "",
      dicas: "",
      labels: [],
      ['visitas_mes']: "",
      users: "",
      graficos: {},
    });

   /* onMounted(async () => {
      loader.open();
      const dataAtual = new Date();
      const dataAtualArray = dataAtual.toLocaleDateString().split("T")[0].split("/");
      dataInicial.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-01`;
      dataFinal.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-${dataAtualArray[0]}`;
      try {
        //const response = await GET(`dashboard/${dataInicial.value}/${dataFinal.value}`);
        //badges.value = response;
        loader.close();
      } catch (e) {
        loader.close();
      }
    });*/

    const getEvento = (id) => {
      const evento = eventos.value.find((e) => e.id == id);
      return evento ? evento.nome : "Evento não encontrado";
    };

    const getPayments = async () =>{
      try {
        const auxPagamento = await GET(`pagamento/ultimos`);
        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))
        eventos.value = await GET("event");
        
        auxPagamento.forEach(async (p) => {
          p.valor = toMoney(p.valor);
          p.evento = getEvento(p.id_evento);
          p.data = new Date(p.data).toLocaleDateString(usuarioConta.timezone);
        });

        Pagamento.value = auxPagamento;   

      } catch (error) {
        console.error(error);
        loader.close();
      }
    }

    const getVendas = async () =>{
      try {
        const auxVenda = await GET(`vendas/ultimos`);
        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))
        
        auxVenda.forEach(async (v) => {
          v.valor = toMoney(v.valor_original);
          v.data = new Date(v.data).toLocaleDateString(usuarioConta.timezone);
        });

        Venda.value = auxVenda;
        
      } catch (error) {
        console.error(error);
      }
    }

    onMounted (async () => {
      console.clear();
      loader.open();
      await getPayments();
      await getVendas();
      loader.close();
    });

    return { Pagamento, badges,headersPagamento,headersVenda, Venda };
  },

  
});
</script>
